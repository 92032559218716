<template>
    <div>
        <div id="micro_site_home">
            <img src="@/assets/img/microsite/home_animals.png" alt="">
            <div class="title_texts_presentation">
                <!-- <h1> {{  productItem  }} </h1> -->
                <h1>Seguro {{  productItem.name  }}</h1>
                <h3>Un dueño responsable prevé el bienestar de su mascota</h3>
                <button @click="quoteProduct">Cotizar Ahora</button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Home",
        data: function() {
            return {
            }
        },
        props: ["productItem"],
        computed: {

        },
        components:{},
        methods:{

            quoteProduct(){
                this.$emit("quoteInsurance")
            }

        }
    }

</script>