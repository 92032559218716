<template>
  <div id="MicroSite_scrollbar">
    <loader v-if="showLoader" />
    <Home v-bind:productItem="productItem[0]" @quoteInsurance="quoteInsurance()" />
    <!-- <GeneralData @quoteInsurance="quoteInsurance()" /> -->
    <!-- <Articles/> -->
    <!-- <Coverages/> -->
    <!-- <Advantages/> -->
    <!-- <Blog/> -->
    <!-- <FooterQuote @quoteInsurance="quoteInsurance()" /> -->
  </div>
</template>
  
<script>
  
  import Home from "@/components/MicroSite/Home"
  // import GeneralData from "@/components/MicroSite/GeneralData"
  // import Articles from "@/components/MicroSite/Articles"
  // import Coverages from "@/components/MicroSite/Coverages"
  // import Advantages from "@/components/MicroSite/Advantages"
  // import Blog from "@/components/MicroSite/Blog"
  // import FooterQuote from "@/components/MicroSite/FooterQuote"

  import { mapGetters } from "vuex";
  import Loader from "../components/Loader.vue"


  export default {
    name: "MicroSite",
    data: function() {
        return {
          productItem:{},
          showLoader:true,
          IdsInsurances:{
            "accidentes-personales":1,
            "bicicleta":2,
            "dental":5,
            "funerario":6,
            "hogar":8,
            "mascota":9,
            "quirurgico":11,
            "viajes":12,
            "vrim":13
          }
        }
    },
    computed: {
      ...mapGetters(["productCategories"]),
    },
    mounted(){
      this.getInsurancesItems()
    },
    methods:{

      async getInsurancesItems(){

        var self = this

        setTimeout(async () => {
          console.log(this.$route.params.insurance)
          var insuranceId = this.IdsInsurances[this.$route.params.insurance]

          self.showLoader = true
          let productCategories = await self.productCategories.getItems()
          self.showLoader = false

          var menuList = productCategories.filter(function(item) {
              return item.id == insuranceId
          })

          self.productItem = menuList
          self.quoteInsurance()
        }, 2000)

      },

      quoteInsurance(){
        var self = this
        this.$store.state.BuyProcessStore.productCategories.setUserSelection(self.productItem)
        this.$router.push("/data-for-services")
      }
      
    },
    components:{
        Loader,
        Home,
        // GeneralData,
        // Articles,
        // Coverages,
        // Advantages,
        // Blog,
        // FooterQuote
    }
  }
</script>

<style>

</style>